try {
    require('bootstrap');
} catch (e) {}

var map;

// function initMap() {
//     map = new google.maps.Map(document.getElementById('map'), {
//         center: {lat: 48.6297676, lng: -2.0406363},
//         zoom: 13
//     });
//
//     var infowindow1 = new google.maps.InfoWindow({
//         content: '<div class="font-size">Marché de Saint Lunaire</div>'
//     });
//
//     var infowindow2 = new google.maps.InfoWindow({
//         content: '<div class="font-size">Dinard, parking de Point S</div>'
//     });
//
//     var infowindow3 = new google.maps.InfoWindow({
//         content: '<div class="font-size">Marché de Dinard</div>'
//     });
//
//     var infowindow4 = new google.maps.InfoWindow({
//         content: '<div class="font-size">Dinan, parking digital sono (rue de brest 22100)</div>'
//     });
//
//     var infowindow5 = new google.maps.InfoWindow({
//         content: '<div class="font-size">Saint Malo place gourmande (6 rue de la balue)</div>'
//     });
//
//     var infowindow6 = new google.maps.InfoWindow({
//         content: '<div class="font-size">Saint Briac, place de la poste</div>'
//     });
//
//     var image = 'https://maps.google.com/mapfiles/kml/pal2/icon55.png';
//
//     var marker1 = new google.maps.Marker({
//         position: {lat: 48.6343311, lng: -2.1114558},
//         map: map,
//         title: 'Marché de Saint Lunaire',
//         icon: image
//     });
//
//     marker1.addListener('click', function () {
//         infowindow1.open(map, marker1);
//         infowindow2.close(map, marker2);
//         infowindow3.close(map, marker3);
//         infowindow4.close(map, marker4);
//         infowindow5.close(map, marker5);
//         infowindow6.close(map, marker6);
//     });
//
//     var marker2 = new google.maps.Marker({
//         position: {lat: 48.6325525, lng: -2.0803447},
//         map: map,
//         title: 'Dinard, parking de Point S',
//         icon: image
//     });
//
//     marker2.addListener('click', function () {
//         infowindow1.close(map, marker1);
//         infowindow2.open(map, marker2);
//         infowindow3.close(map, marker3);
//         infowindow4.close(map, marker4);
//         infowindow5.close(map, marker5);
//         infowindow6.close(map, marker6);
//     });
//
//     var marker3 = new google.maps.Marker({
//         position: {lat: 48.6341202, lng: -2.0550781},
//         map: map,
//         title: 'Marché de Dinard',
//         icon: image
//     });
//
//     marker3.addListener('click', function () {
//         infowindow1.close(map, marker1);
//         infowindow2.close(map, marker2);
//         infowindow3.open(map, marker3);
//         infowindow4.close(map, marker4);
//         infowindow5.close(map, marker5);
//         infowindow6.close(map, marker6);
//     });
//
//     var marker4 = new google.maps.Marker({
//         position: {lat: 48.4496198, lng: -2.0695937},
//         map: map,
//         title: 'Dinan, parking digital sono (rue de brest 22100)',
//         icon: image
//     });
//
//     marker4.addListener('click', function () {
//         infowindow1.close(map, marker1);
//         infowindow2.close(map, marker2);
//         infowindow3.close(map, marker3);
//         infowindow4.open(map, marker4);
//         infowindow5.close(map, marker5);
//         infowindow6.close(map, marker6);
//     });
//
//     var marker5 = new google.maps.Marker({
//         position: {lat: 48.6289217, lng: -2.0082608},
//         map: map,
//         title: 'Saint Malo place gourmande (6 rue de la balue)',
//         icon: image
//     });
//
//     marker5.addListener('click', function () {
//         infowindow1.close(map, marker1);
//         infowindow2.close(map, marker2);
//         infowindow3.close(map, marker3);
//         infowindow4.close(map, marker4);
//         infowindow5.open(map, marker5);
//         infowindow6.close(map, marker6);
//     });
//
//     var marker6 = new google.maps.Marker({
//         position: {lat: 48.619327, lng: -2.1345941},
//         map: map,
//         title: 'Saint Briac, place de la poste',
//         icon: image
//     });
//
//     marker5.addListener('click', function () {
//         infowindow1.close(map, marker1);
//         infowindow2.close(map, marker2);
//         infowindow3.close(map, marker3);
//         infowindow4.close(map, marker4);
//         infowindow5.close(map, marker5);
//         infowindow6.open(map, marker6);
//     });
// }
